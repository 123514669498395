.Container {
    margin-top: 20px;
}
    .NoUsers {
        width: 60%;
        margin: auto;
        margin-top: 20px;
    }
    .NoUsers h1 {
        color: #D92635;
        margin: 0;
        font-size: 50pt;
    }
    .NoUsers h3 {
        text-transform: capitalize;
        margin: 0;
    }
    .NoUsers h4 {
        font-weight: normal;
        color: #888888;
    }
@media only screen and (max-width: 500px) {
    .NoUsers {
        width: 90%;
    }
}