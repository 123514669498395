.Container {
    height: 100%;
    width: 100%;
}
    .Container > h2 {
        font-weight: normal;
        text-transform: capitalize;
    }
    .Container form {
        width: 90%;
        margin: auto;
    }
    .Container .Field {
        height: fit-content;
    }
        .Field > h5 {
            margin: 10px 0;
            padding: 0;
            text-align: start;
            font-weight: normal;
        }
            .Field h5::first-letter {
                text-transform: uppercase;
            }
        .Field button {
            margin: 30px;
        }
    .Container .Fieldset {
        display: flex;
        justify-content: space-between;
    }
    .Container .Fieldset * {align-content: center;}