.Container {
    align-self: center;
    border-radius: 100px;
    background: #f7f7f7;
    transition: .3s ease;
    position: relative;
    display: flex;
    width: fit-content;
    color: unset;
    text-decoration: none;
    cursor: pointer;
}
.Container:hover {
    background: #ffd2d6;
}
    .Photo {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        align-self: center;
    }
        .Photo img {
            display: block;
            width: 100%;
        }
    .Metadata {
        margin-left: 10px;
    }
        .Metadata h6:first-child {
            margin: 0;
            text-align: start;
            margin-bottom: 0;
            text-transform: capitalize;
            font-size: 9pt;
            color: #D92635;
        }
        .Metadata h6 {
            margin: 0;
            text-align: start;
            font-weight: lighter;
        }
        .Metadata h6:last-child {
            text-transform: capitalize;
            margin-top: 5px;
        }
.AuthControls {
    padding: 10px;
    height: fit-content;
    align-self: center;
    display: flex;
    justify-content: space-between;
}
    .AuthControls button {
        display: block;
        padding: 5px 15px;
        height: 40px;
        font-size: 9pt;
    }

    .AuthControls button:last-child {
        margin-left: 10px;
    }
@media only screen and (max-width: 800px) {
    .Container {
        padding: 10px;
        margin-left: auto;
    }
    .Container .Metadata {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .AuthControls button {
        display: block;
        padding: 5px 5px;
        height: 40px;
        font-size: 6pt;
    }
}