.Container {
    width: 100%;
    height: 100%;
}
    .CodeContainer {
        height: 95%;
        width: 100%;
        overflow-y: scroll;
        display: flex;
    }
    .CodeContainer::-webkit-scrollbar {
        height: 5px;
        width: 10px;
    }
    .CodeContainer::-webkit-scrollbar-thumb {
        background: transparent;
        transition: .5s ease;
    }
    .CodeContainer:hover::-webkit-scrollbar-thumb {
        background: #0000003d;
    }
    .CodeContainer::-webkit-scrollbar-thumb:hover {
        background: #00000085;
    }
    .LineNumbers {
        padding: 0 10px;
        text-align: end;
        margin-right: 10px;
        height: fit-content;
        position: sticky;
        left: 0;
        background: white;
        margin-top: 3px;
    }
        .LineNumbers code {
            display: block;
            color: #7c7c7c93;
            border: 2px solid transparent;
        }
            .LineNumbers .Active {
                color: #D92635;
                background: #7070701c;
            }
    .Editor {
        height: 100%;
        width: fit-content;
    }
        [contenteditable] {
            -webkit-user-select: text;
            user-select: text;
        }
        .Editor:active,
        .Editor:focus {
            outline: none;
        }
            .Line {
                display: block;
                white-space: nowrap;
                border: 2px solid transparent;
                font-family: Consolas, 'Courier New', monospace;
            }
                .Line:hover, 
                .Line:active, 
                .Line:focus {
                    border-color: #d3d3d3ce;
                }
                .Editor .Active {
                    background: #70707031;
                }
    .Metadata {
        height: 5%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
        .Metadata h6 {
            align-self: center;
            margin-right: 10px;
            color: #D92635;
        }
        .Metadata h6:last-child {text-transform: uppercase;}
    .Intellisense {
        position: fixed;
        width: 300px;
        max-height: 200px;
        padding: 5px;
        background: white;
        box-shadow: 0 0 10px #0000004d;
        border: 1px solid lightgray;
        overflow-y: scroll;
        transition: .3s ease;
    }
        .Intellisense::-webkit-scrollbar {
            height: 5px;
            width: 10px;
        }
        .Intellisense::-webkit-scrollbar-thumb {
            background: transparent;
            transition: .5s ease;
        }
        .Intellisense:hover::-webkit-scrollbar-thumb {
            background: #0000003d;
        }
        .Intellisense::-webkit-scrollbar-thumb:hover {
            background: #00000085;
        }
        .Intellisense h5 {
            margin: 5px;
            font-weight: normal;
            cursor: pointer;
        }
            .Intellisense h5:active,
            .Intellisense h5:hover,
            .Intellisense h5:focus {
                background: #d926353a;
                outline: none;
            }


/* HTML Syntax Theme */
.html-fmtr-t-eo {color: #00f;}
.html-fmtr-t {color: #00f;}
.html-fmtr-t-p {color: #f00;}
.html-fmtr-t-ps {color: #00f;}
.html-fmtr-t-pv {color: #a31515;}
.html-fmtr-t-ee {color: #00f;}