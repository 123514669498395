.Continer {}
    .Field {
        display: flex;
        width: 50%;
        margin: auto;
        padding: 20px 0px;
        border-top: 1px solid #f1f1f1;
    }
        .Field:first-child {
            border-top: none;
        }
        .Field .Meta {
            margin-right: 20px;
            height: fit-content;
            padding: 10px;
            width: 50%;
        }
            .Meta h5 {
                text-transform: capitalize;
                text-align: start;
                margin: 0;
                padding: 0;
                font-size: 11pt;
            }
            .Meta h6 {
                font-weight: normal;
                margin: 0;
                padding: 0;
                margin-top: 10px;
                text-align: start;
                font-size: 10pt;
            }
            .Meta button {
                display: block;
                margin-top: 30px;
            }
        .Field .FieldSet {
            width: 50%;
            align-self: center;
            margin-left: 10px;
            margin-left: auto;
        }
            .Field .FieldSet .Input {}
            .Field .FieldSet .Input h6 {
                margin: 0;
                text-align: start;
                margin: 10px 0px;
                text-transform: capitalize;
                font-size: 9pt;
            }
        .Field .Toggles {
            align-self: center;
            margin-left: 10px;
            margin-left: auto;
        }
            .Toggles button {
                margin-left: 10px;
            }
        .Controls {
            margin-top: 20px;
        }
@media only screen and (max-width: 1000px) {
    .Continer .Field {
        width: 100%;
        display: block;
    }
    .Continer .Toggles {
        text-align: start;
        margin-top: 10px;
    }
    .Continer .Meta {
        width: 100%;
        padding: 0;
    }
    .Continer .FieldSet {
        width: 100%;
    }
}

@media only screen and (min-width: 1000px) {
    .Continer .Field {
        width: 80%;
    }
}

