.Container {
    width: 100%;
    height: 94vh;
    overflow: hidden;
    position: relative;
    margin-top: 7vh;
}
    .Loading {
        width: 80px;
        height: 80px;
        position: absolute;
        left: calc( calc( 100% - 80px ) / 2 );
        top: calc( calc( 100% - 80px ) / 2 );
        border-radius: 100%;
        overflow: hidden;
        animation-name: Loading;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-delay: 1s;
        animation-iteration-count: infinite;
        display: flex;
        background: white;
    }
        .Loading img {
            width: 70px;
            height: 70px;
            align-self: center;
            margin: auto;
        }
        @keyframes Loading {
            0% {transform: rotate(0deg);}
            25% {transform: rotate(90deg);}
            50% {transform: rotate(180deg);}
            75% {transform: rotate(270deg);}
            100% {transform: rotate(360deg);}
        }
    .NotAuthorized {
        width: 400px;
        height: 300px;
        position: absolute;
        left: calc( calc( 100% - 400px ) / 2 );
        top: calc( calc( 100% - 300px ) / 2 );
        text-align: center;
    }
        .NotAuthorized h1,
        .NotAuthorized h4, 
        .NotAuthorized h5 {
            margin: 0;
        }
            .NotAuthorized h1 {
                font-size: 50pt;
                color: #D92635;
            }
            .NotAuthorized h4 {
                margin-bottom: 10px;
                color: #D92635;
            }
            .NotAuthorized h5 {
                font-weight: normal;
                text-align: center;
            }
            .NotAuthorized h4::first-letter,
            .NotAuthorized h5::first-letter {
                text-transform: uppercase;
            }

    .Dashboard {
        width: 100%;
        height: 100%;
        display: flex;
    }
       