.Container {
    width: 50px;
    height: 20px;
    display: flex;
    cursor: pointer;
    background: #e7e7e7;
    border-radius: 100px;
}
    .Container:hover .Radio {
        transform: scale(1.2);
    }
    .Radio {
        overflow: hidden;
        border: 1px solid lightgray;
        border-radius: 100%;
        align-self: center;
        width: 20px;
        height: 20px;
        display: flex;
        background: #D92635;
        transition: .3s ease;
    }
    .Container h5 {
        margin-left: 20px;
        font-weight: normal;
        text-transform: capitalize;        
    }
    .Selected .Radio {
        margin-left: auto;
    }