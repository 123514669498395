.Container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 13;
    transition: .3s ease;
}
    .show-container {visibility: visible;}
    .hide-container {visibility: hidden;}   
    .Overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: .2s ease-out;
    }
    .show-overlay {
        background-color: #e2e2e2de;
        /* backdrop-filter: blur(10px); */
    }
    .hide-overlay {background-color: transparent;}

    .Header {
        height: 55px;
        display: flex;
        position: sticky;
        top: 0;
        z-index: 11;
    }
        .Header h5 {
            font-size: 11pt;
            align-self: center;
            margin-left: 20px;
            font-weight: normal;
            text-transform: capitalize;
        }
            .Header h5 span {
                font-size: 13pt;
            }
        .Header .Control {
            margin-left: auto;
            margin-right: 20px;
            align-self: center;
        }
    .Modal {
        position: absolute;
        width: 60%;
        height: 100vh;
        top: 0;
        left: 42%;
        padding: 10px;
        background-color: white;
        transition: .2s ease;
        z-index: 10;
        box-shadow: 0px 0px 20px #0000005d;
        opacity: 0;
        overflow: hidden;
    }   
        .show-modal { 
            left: 39%;
            opacity: 1;
        }
        .hide-modal { 
            left: 40%;
            opacity: 0;
            visibility: hidden;
        }
        .Modal .Container {
            width: inherit;
            position: relative;
            width: 100%;
        }
@media only screen and (max-width: 1200px) {
    .Modal-Controls {
        left: 64%;
        top: 1%;
        width: 35%;
        display: flex;
    }
    .Modal-Controls button {
        margin-left: auto;
    }
}
@media only screen and (max-width: 1000px) {
    .Modal {width: 70%;}
    .show-modal { left: 30%;opacity: 1; }
    .hide-modal { left: 35%;opacity: 0; }
}
@media only screen and (max-width: 800px) {
    .Modal {width: 80%;}
    .show-modal { left: 20%;opacity: 1; }
    .hide-modal { left: 25%;opacity: 0; }
    .Modal-Controls {
        width: 60%;
        left: 39%;
    }
}
@media only screen and (max-width: 650px) {
    .Modal { width: 100% }
    .show-modal { left: 0%;opacity: 1; }
    .hide-modal { left: 10%;opacity: 0; }
}

