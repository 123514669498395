.Container {
    display: flex;
    cursor: pointer;
}
    .Container h5 {
        margin: 0;
        padding: 0;
        width: 25px;
        color: #D92635;
        align-self: center;
        margin-right: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        transition: .3s ease;
    }
        .Container h5 span {opacity: 0;}
    .Container:hover h5 span { opacity: 1; }
    .Container p {
        margin: 0;
        padding: 0;
        font-size: 9pt;
    }
    .Checked h5 {
        background: #D92635;
        color: white;
    }
    .Checked h5 span {
        opacity: 1;
    }