.Container {
    width: 100%;
    height: fit-content;
    margin-right: 10px;
    border: 1px solid #0984e3;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
    .Preview {
        position: static;
        height: 190px;
        width: 100%;
    }
        .Preview .Title {
            position: absolute;
            background: #0984e3;
            z-index: 2;
            left: 10px;
            top: 10px;
            padding: 7px;
            font-size: 11pt;
            color: white;
            border-radius: 5px;
        }
    .Fieldset {
        display: flex;
        width: 100%;
    }
    .Title {
        margin-bottom: 5px;
        text-transform: capitalize;
        font-weight: normal;
    }
    .Label {
        color: #0984e3;
        border-right: none;
        font-weight: normal;
        width: 50px;
        display: flex;
    }
    .Label h2 {
        font-size: 12pt;
        width: fit-content;
        margin: auto;
        align-self: center;
    }
    .Input {
        width: 100%;
        text-align: left;
    }
    .Input input {
        transition: .2s ease-out;
        width: 100%;
        font-size: 11pt;
        border: none;
        background: transparent;
    }
    .Input input:active,
    .Input input:focus {
        outline: none;
    }
    .Input input[type=file]::-webkit-file-upload-button,
    .Input input[type=file]::file-selector-button,
    .Input input[type=file]::-ms-browse {
        width: 100%;
        color: black;
        background: #e2e2e2;
        border: none;
        outline: none; 
        padding: 10px;
        cursor: pointer;
    }

    .Input input::-webkit-file-upload-button:hover,
    .Input input[type=file]::file-selector-button:hover {
        background: #d9263546;
    }
    .Input input:disabled  {
        opacity: .5;
    }