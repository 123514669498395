.Container {
    
}

    .Header {
        display: flex;
        padding: 5px 10px;
    }
        .Active {
            background: #26d935;
            display: flex;
            height: 30px;
            width: 30px;
            border-radius: 100%;
            align-self: center;
            margin-right: 10px;
        }
            .Active span {
                display: block;
                align-self: center;
                margin: auto;
                color: white;
            }
        .Header .Tag {
            background: #f0f0f0; 
            padding: 5px 10px;
            border-radius: 10px;
            cursor: pointer;
        }
        .Header button { 
            margin-left: auto;
            align-self: center;
        }
    .Name {
        text-align: start;
    }
    .Text {
        text-align: justify;
        margin: 0;
        font-size: 11pt;
        font-style: italic;
        color: #666666;
    }