.Container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 13;
    transition: .3s ease-out;
}
    .show-container {visibility: visible;}
    .hide-container {visibility: hidden;}   
    .Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: .3s ease-out;
    }
    .show-overlay {
        background-color: transparent;
    }
    .hide-overlay {background-color: transparent;}

    .Popup {
        position: absolute;
        top: 5%;
        left: calc( 100% - 350px);
        height: 300px;
        width: 200px;
        padding: 10px;
        justify-content: space-evenly;
        background: white;
        border-radius: 5px;
        box-shadow: 0 0 20px #00000048;
        border: 1px solid #b8b8b8;
        transition: .3s ease;
        text-align: center;
        opacity: 0;
        visibility: hidden;
    }
    .show-popup { 
        top: 8%;
        opacity: 1;
        visibility: visible;
    }
    .hide-popup {
        top: 5%;
        opacity: 0;
        visibility: hidden;
    }
        .Popup .Header h3 {
            text-transform: capitalize;
            font-size: 9pt;
        }
        .Popup .Header p {
            font-size: 8pt;
            text-align: start;
            width: 80%;
            margin: auto;
            margin-bottom: 20px;
        }
        .Popup .Header p::first-letter {
            text-transform: uppercase;
        }   
            .Popup .WidgetControls a {
                text-decoration: none;
                font-size: 9pt;
                background: #D92635;
                color: white;
                width: 80%;
                display: block;
                margin: auto;
                padding: 10px;
                border-radius: 10px;
                margin-top: 10px;
            }
            .Popup .WidgetControls button {
                width: 90%;
                display: block;
                font-size: 9pt;
                margin: auto;
                margin-top: 10px;
            }
    @media only screen and (max-width: 800px) {
       .Container {}
    }
    @media only screen and (max-width: 380px) {
        .Container {
            left: 10%;
        }
    }