.Container {
    width: 100%;
    position: relative;
    text-align: start;
}
    .Overlay {display: none;}
    .Active .Overlay {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: transparent;
        z-index: 15;
    }
    .Loading {
        opacity: .5;
    }
    .Disabled { opacity: .5 }
    .Title {
        border-radius: 10px;
        height: fit-content;
        display: flex;
        border: 2px solid lightgray;
        cursor: pointer;
        height: 50px;
    }
        .Title > h5 {
            text-transform: capitalize;
            align-self: center;
            width: 90%;
            height: 15px;
            font-size: 9pt;
            margin-left: 10px;
        }
        .Title h4 {
            margin-left: auto;
            margin-right: 10px;
            align-self: center;
        }
    .Menu {
        width: 150px;
        padding: 10px 0px;
        position: absolute;
        top: 45px;
        left: calc( 100% - 150px );
        max-height: 130px;
        overflow-y: scroll;
        background: white;
        border-radius: 10px;
        z-index: 16;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0px 0px 10px #00000028;
        transition: .3s ease;
        border: 1px solid lightgray;
    }
        .Active .Menu {
            opacity: 1;
            top: 40px;
            visibility: visible;
        }
        .Menu::-webkit-scrollbar {
            height: 5px;
            width: 5px;
        }
        .Menu::-webkit-scrollbar-thumb {
            background: transparent;
            transition: .5s ease;
            border-radius: 10px;
        }
        .Menu:hover::-webkit-scrollbar-thumb {
            background: #0000003d;
        }
        .Menu::-webkit-scrollbar-thumb:hover {
            background: #00000085;
        }
        .Menu h5 {
            padding: 10px;
            text-transform: capitalize;
            cursor: pointer;
            font-size: 9pt;
            text-align: center;
            margin: 0;
        }
            .Menu h5:hover {
                background: #e309091e;
            }
        .Active {}
        