.Container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 13;
    transition: .2s ease-out;
}
    .show-container {visibility: visible;}
    .hide-container {visibility: hidden;}   
    .Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: .2s ease-out;
    }
    .show-overlay {
        background-color: #e2e2e2de;
        /* backdrop-filter: blur(10px); */
    }
    .hide-overlay {background-color: transparent;}

    .Popup {
        position: absolute;
        top: calc( calc(100% - 520px) / 2 );
        left: calc( calc(100% - 40%) / 2 );
        width: 40%;
        max-height: 550px;
        min-height: 300px;
        padding: 20px;
        padding-bottom: 30px;
        background: white;
        transition: .2s ease-in-out;
        border-radius: 10px;
        z-index: 10;
        box-shadow: 0px 0px 50px #00000036;
        
        opacity: 0;
        overflow: hidden;
    }
    .show-popup { 
        top: calc( calc(100% - 550px) / 2 );
        opacity: 1;
    }
    .hide-popup {
        top: calc( calc(100% - 520px) / 2 );
        opacity: 0;
    }
        .Popup .Header {
            width: 100%;
            height: 40px;
            display: flex;
            margin-top: 15px;
            position: relative;
        }
        .Popup .Header h5 {
            height: fit-content;
            align-self: center;
            margin: auto;
            text-transform: capitalize;
            font-size: 13pt;
        }
            .Popup .Header button {
                height: 40px;
                width: 40px;
                background: white;
                color: black;
                font-size: 14pt;
                margin-left: auto;
                position: absolute;
                left: calc( 100% - 40px );
            }
        .Popup .Content {
            width: 100%;
            max-height: 450px;
            overflow-y: scroll;
        }
            .Popup .Content::-webkit-scrollbar {
                height: 5px;
                width: 5px;
            }
            .Popup .Content::-webkit-scrollbar-thumb {
                background: transparent;
                transition: .5s ease;
                border-radius: 10px;
            }
            .Popup .Content:hover::-webkit-scrollbar-thumb {
                background: #0000003d;
            }
            .Popup .Content::-webkit-scrollbar-thumb:hover {
                background: #00000085;
            }
        .Form {
            width: 80%;
            margin: auto;
        }
            .Form .Field {
                margin-top: 10px;
            }
            .Form .Controls {
                margin-top: 10px;
            }
                .Form .Controls button {
                    width: 100%;
                    padding: 20px;
                }
        .Providers {
            width: 80%;
            margin: auto;
            border-top: 1px solid rgb(223, 223, 223);
            margin-top: 20px;
            padding-top: 10px;
        }
            .Provider {
                width: 100%;
                margin-bottom: 10px;
            }
                .Provider button {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    border-radius: 50px;
                    color: #D92635;
                    background: rgb(240, 240, 240);
                }
                    .Provider button span {
                        display: block;
                        align-self: center;
                        font-size: 20pt;
                    }

@media only screen and (max-width: 600px) {
    .Popup {
        left: calc( calc( 100% - 550px ) / 2 );
    }
}
@media only screen and (max-width: 600px) {
    .Popup {
        width: 400px;
        left: calc( calc( 100% - 450px ) / 2 );
    }
}
@media only screen and (max-width: 500px) {
    .Popup {
        width: 300px;
        min-height: 200px;
        left: calc( calc( 100% - 350px ) / 2 );
    }
}
@media only screen and (max-width: 400px) {
    .Popup {
        width: 250px;
        min-height: 100px;
        left: calc( calc( 100% - 300px ) / 2 );
    }
}