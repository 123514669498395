.Container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 13;
    transition: .3s ease-out;
}
    .show-container {visibility: visible;}
    .hide-container {visibility: hidden;}   
    .Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: .3s ease-out;
    }
    .show-overlay {
        background-color: transparent;
    }
    .hide-overlay {background-color: transparent;}

    .Popup {
        position: absolute;
        top: 8vh;
        left: calc( 100% - 250px );
        height: 450px;
        width: 200px;
        padding: 10px;
        justify-content: space-evenly;
        background: white;
        border-radius: 5px;
        box-shadow: 0 0 20px #00000048;
        border: 1px solid #b8b8b8;
        transition: .3s ease;
        opacity: 0;
        visibility: hidden;
    }
    .show-popup { 
        top: 9vh;
        opacity: 1;
        visibility: visible;
    }
    .hide-popup {
        top: 8vh;
        opacity: 0;
        visibility: hidden;
    }

@media only screen and (max-width: 800px) {
    .Popup {width: 60%;}
}

@media only screen and (max-width: 400px) {
    .Popup {width: 80%;}
}

    .Photo {
        width: 100px;
        height: 100px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 100%;
        overflow: hidden;
    }
    .Metadata {
        text-align: center;
        margin-bottom: 10px;
    }
    .Metadata h3,
    .Metadata h4 {
        margin: 0;
        font-size: 9pt;
        font-weight: normal;
    }
        .Metadata h3 {
            font-size: 10pt;
            font-weight: bold;
            text-transform: capitalize;
        }
        .Metadata h4:nth-child(3) {
            color: #a30a17;
            text-transform: capitalize;
        }
    .Metadata button {
        font-size: 8pt;
        margin: auto;
        margin-top: 15px;
    }
    .Tab a {
        font-size: 9pt;
        width: 100%;
        text-transform: capitalize;
        display: block;
        margin-top: 5px;
        color: unset;
        text-decoration: none;
        transition: .3s ease;
        border-radius: 20px;
    }
        .Tab a:hover {
            background: #a30a1718;
        }
        .Tab a p {
            width: 90%;
            margin: auto;
            padding: 10px;
            padding-left: 20px;
        }