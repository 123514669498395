.Container {
    width: 48%;
    height: 99%;
    overflow: hidden;
    margin: 0px auto;
    position: relative;
}
    .HidePreviwer {display: none;}
    .DisableEditor,
    .HideEditor {width: 100%;}
    .HidePreviwer {
        width: 95%;
        margin: 0px auto;
    }
        .Container:hover .Controls{
            opacity: 1;
        }
        .Container .Controls {
            width: 94%;
            margin: auto;
            height: 50px;
            display: flex;
            opacity: .5;
            transition: .3s ease;
            padding: 0 10px;
            overflow-x: scroll;
        }
        .Container .Controls::-webkit-scrollbar {
            height: 5px;
            width: 10px;
        }
        .Container .Controls::-webkit-scrollbar-thumb {
            background: transparent;
            transition: .5s ease;
        }
        .Container .Controls:hover::-webkit-scrollbar-thumb {
            background: #0000003d;
        }
        .Container .Controls::-webkit-scrollbar-thumb:hover {
            background: #00000085;
        }
            .Controls button {
                align-self: center;
                margin-left: 10px;
            }
            .Controls button:first-child {
                margin-left: auto;
            }
            .Render {
                height: 100%;
                margin: auto;
            }
            .Render .Iframe {
                border: none;
            }
            .Render .Iframe::-webkit-scrollbar {
                height: 5px;
                width: 10px;
            }
            .Render .Iframe::-webkit-scrollbar-thumb {
                background: transparent;
                transition: .5s ease;
            }
            .Render .Iframe:hover::-webkit-scrollbar-thumb {
                background: #0000003d;
            }
            .Render .Iframe::-webkit-scrollbar-thumb:hover {
                background: #00000085;
            }
                .NoContent {
                    text-align: center;
                    transition: .3s ease;
                }
                .NoContent h1 {
                    color: #D92635;
                    font-size: 50pt;
                }
                .NoContent h3::first-letter {
                    text-transform: capitalize;
                }
                .NoContent h5::first-letter {
                    text-transform: capitalize;
                }
                .NoContent h5 {
                    font-weight: normal;
                    line-height: 40px;
                }
                    .NoContent h5 .Code {
                        background: lightgray;
                        padding: 5px;
                        border-radius: 5px;
                    }
            .ShareWidget {
                height: fit-content;
                align-self: center;
                display: flex;
            }
                .ShareWidget button {
                    color: white;
                    margin: 0;
                }
            .ShareWidget .Line {
                height: 1px;
                width: 10px;
                align-self: center;
            }
            .TwitterBtn {
                background: #31a4f1;
            }
            .FacebookBtn {
                background: #2374e1;
            }