.Container {
    width: 100%;
    height: 100%;
    background: #0000001e;
    z-index: 10;
    transition: .3s ease;
    animation-name: Pulse;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-iteration-count: infinite;
}
    .Hide {
        animation-play-state: paused;
        display: none;
    }
    .Show {
        animation-play-state: running;}

@keyframes Pulse {
    100% {background: #00000071;}
    50% {background: #0000003f;}
    0% {background: #00000071;}
}