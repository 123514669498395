.Container {
    margin-top: 20px;
}
    .NoTemplates {
        width: 60%;
        margin: auto;
        margin-top: 20px;
    }
    .NoTemplates h1 {
        color: #D92635;
        margin: 0;
        font-size: 50pt;
    }
    .NoTemplates h3 {
        text-transform: capitalize;
        margin: 0;
    }
    .NoTemplates h4 {
        font-weight: normal;
        color: #888888;
    }
    .Search {
        display: flex;
    }
        .Search div {
            width: 100%;
        }
        .Search button {
            align-self: center;
            transform: scale(.9);
            margin-right: 20px;
        }
    .Collection {
        clear: both;
    }
    .Paganation {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
        .Paganation button {
            display: block;
            margin-left: 10px;
        }
@media only screen and (max-width: 500px) {
    .NoTemplates {
        width: 90%;
    }
}