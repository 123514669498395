.Container {
    position: relative;
    width: 100%;
    height: 100%;
}
    .Container > h2 {
        font-weight: normal;
        text-transform: capitalize;
    }
    .NoActiveServiceText,
    .NoSources {
        width: 70%;
        margin: auto;
    }
    .NoActiveServiceText h3,
    .NoSources h3 {
        text-transform: capitalize;
    }
    .NoActiveServiceText p::first-letter,
    .NoSources p::first-letter {
        text-transform: uppercase;
    }
    
    .Header {
        text-align: end;
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
        display: flex;
    }
        .Header .Search {
            align-self: center;
        }
        .Header button {
            margin-left: auto;
            align-self: center;
        }
    .ActiveSources,
    .AvailableSources {
        width: 90%;
        margin: auto;
        padding: 10px 15px;
        margin-top: 20px;
        border-radius: 20px;
        border: 1px solid #D92635;
    }
    .AvailableSources {border: none;}
    .ActiveSources > p,
    .AvailableSources > p {
        text-align: start;
        text-transform: capitalize;
        font-size: 10pt;
    } 
    .ActiveSources .Prompt,
    .AvailableSources .Prompt {
        text-align: center;
        color: #444444;
        text-transform: lowercase;
    }