.Container {
    background: #f5f5f5;
    width: 290px;
    min-height: 190px;
    padding: 10px 20px;
    border-radius: 10px;
    float: left;
    margin-left: 20px;
    margin-top: 20px;
    border: 1px solid #d8d8d8;
}
    .Container:hover {
        border-color: #D92635;
    }
    .Container > h6 {
        margin: 0;
        padding: 0;
        text-align: start;
        font-weight: normal;
    }
    .Header {
        display: flex;
    }
        .Header a {
            align-self: center;
            text-decoration: none;
            color: unset;
            text-transform: capitalize;
        }
        .Header .NoType {
            font-style: italic;
            font-weight: normal;
            font-size: 10pt;
        }
        .Header > div {
            align-self: center;
            margin-left: auto;
        }
    .Description {
        text-align: start;
        margin: 0;
    }
    .Meta {
        text-align: start;
        text-transform: capitalize;
    }
        .Meta h5 {
            margin: 0;
            padding: 0;
            font-weight: normal;
        }
    .Commit {
        text-align: start;
    }
        .Commit p {font-size: 9pt;}
        .Commit button {
            padding: 5px 20px;
        }