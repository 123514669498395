.Container {
    width: 48%;
    height: 99%;
    overflow: hidden;
    margin: 0px auto;
    border: 1px solid #ececec;
    border-radius: 10px;
}
    .HideEditor,
    .DisableEditor {display: none;}
    .HidePreviwer {
        width: 100%;
        margin: 0px auto;
    }
    .DisableEditor {
        display: none;
    }
        .Container .Textarea {
            padding: 0px 10px;
        }
        .Container:hover .Controls{
            opacity: 1;
        }
        .Container .Controls {
            width: 94%;
            margin: auto;
            height: 50px;
            display: flex;
            /* background: maroon; */
            opacity: .5;
            transition: .3s ease;
            padding: 0 10px;
        }
            .Controls button {
                align-self: center;
                margin-left: 10px;
            }
            .Controls button:first-child {
                margin-left: auto;
            }
            .Container .Textarea {
                width: 97%;
                height: calc( 100% - 50px );
            }
                .Container .Textarea textarea {
                    background: transparent;
                }
            .Container .Textarea textarea,
            .Container .Textarea textarea:active,
            .Container .Textarea textarea:focus {
                outline: none;
                border: none;
            }