.Header {
    display: flex;
    height: 7vh;
    width: 95%;
    position: fixed;
    top: 0;
    left: 2.5%;
    background: rgba(255, 255, 255, 0.486);
    z-index: 10;
    align-items: center;
    margin: auto;
    background: white;
}
    .Logo img {
        width: 50px;
    }
        .Logo a {
            display: block;
            height: fit-content;
            padding: 0;
            margin: 0;
        }
    .Controls {
        display: flex;
        margin-left: auto;
        margin-right: 20px;
    }
        .Controls button {
            margin-left: 5px;
            padding: 10px 12px;
            height: 40px;
        }
    

@media only screen and (max-width: 500px) {
    .Controls {
        margin: auto;
        align-self: center;
    }
    .Controls button {
        width: 30px;
        padding: 2px;
        height: 30px;
        font-size: 8pt;
    }
}
