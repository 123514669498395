.Container {
    background-color: #f3f3f3;
    display: flex;
    padding: 10px 20px;
    height: 70px;
    border-radius: 10px;
    margin-top: 10px;
    overflow: hidden;
    transition: .3s ease;
    position: relative;
}
    .Container:hover {
        background: #eeeeee;
    }
    .Photo {
        min-width: 50px;
        width: 50px;
        min-height: 50px;
        height: 50px;
        align-self: center;
        border-radius: 100%;
        overflow: hidden;
    }
    .Metadata {
        margin-left: 20px;
        align-self: center;
    }
        .Metadata h5,
        .Metadata h6 {
            margin: 0;
            text-align: start;
        }
        .Metadata h6 {
            font-weight: normal;
        }
    .Controls {
        align-self: center;
        display: flex;
        margin-left: auto;
        height: fit-content;
    }
        .Controls .Roles {
            height: fit-content;
            padding-right: 10px;
            border-right: 1px solid lightgray;
        }
        .Controls .Account {
            padding-left: 10px;
        }
        .Controls .Roles,
        .Controls .Account {
            align-self: center;
            display: flex;
        }
        .Controls .Roles button {
            margin-left: 5px;
            font-size: 12pt;
        }
@media only screen and (max-width: 500px) {
    .Container {
        height: 90px;
    }
    .Container .Photo,
    .Container .Metadata {
        align-self: unset;
    }
    .Container .Metadata {width: 100%;}
    .Container .Photo {margin-top: 10px;}
    .Container .Metadata {margin-top: 7px;}
    .Container:hover {
        height: 150px;
    }
    .Container .Controls {
        position: absolute;
        width: fit-content;
        left: 80px;
        top: 110px;
        border: none;
    }
    .Container .Roles {
        border: none;
    }
}