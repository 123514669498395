.Container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
    .Loading {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .Container img { 
        width: 100%;
    }

    .Update {
        width: 100%;
        height: 100%;
        margin: 0;
        margin-top: 5px;
        border-radius: 20px;
        overflow: hidden;
    }
        .Update > div {
            width: 100%;
            border: none;
        }
        .Update > div input {
            width: 100%;
        }
        .Update > div input[type=file]::-webkit-file-upload-button,
        .Update > div input[type=file]::file-selector-button  {
            width: 100%;
            color: black;
            background: #e2e2e2;
            border: none;
            outline: none;
            padding: 10px;
            cursor: pointer;
        }