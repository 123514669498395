.Container {
    background: #eeeeee;
    width: 80%;
    margin: auto;
    text-align: start;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    margin-top: 20px;
} 
    .Container a {
        color: unset;
        text-decoration: none;
    }
    .Container:hover {border-color: #D92635;}
    .Container p {
        padding: 5px 20px;
    }
    .Container p:first-child {
        color: #D92635;
    }
    .Container p:last-child {
        font-size: 10pt;
    }