.Container {
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
}
    .Header {
        width: 400px;
        display: flex;
        justify-content: space-evenly;
    }
    .Calendar {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
    }
        .Calendar h5 {
            font-size: 11pt;
            font-weight: normal;
        }
        .Calendar p {
            color: #161616;
            padding: 10px;
            width: 25px;
            border-radius: 100%;
            margin: 10px auto;
            transition: .3s ease;
        }
            .Calendar .Prefix {
                opacity: .4;
            }
            .Calendar p:hover,
            .Calendar .Active {
                background: #D92635;
                color: white;
                font-weight: normal;
                cursor: pointer;
            }
    @media only screen and (max-width: 400px) {
        .Container {
            transform: scale(.7);
            margin-left: calc( calc( 100vw - 400px ) / 2 );
        }
    }