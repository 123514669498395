.Container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 13;
    transition: .2s ease-out;
}
    .show-container {visibility: visible;}
    .hide-container {visibility: hidden;}   
    .Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: .2s ease-out;
    }
    .show-overlay {
        background-color: #e2e2e2de;
        /* backdrop-filter: blur(7px); */
    }
    .hide-overlay {background-color: transparent;}

    .MessageBox {
        position: absolute;
        top: calc( calc(100% - 300px) / 2 );
        left: calc( calc(100% - 560px) / 2 );
        width: 500px;
        min-height: 240px;
        padding: 30px;
        padding-bottom: 30px;
        background: white;
        transition: .2s ease;
        border-radius: 5px;
        z-index: 10;
        box-shadow: 0px 0px 50px #0000005d;
        transform: scale(.95);
        opacity: 0;
    }
    .show-messageBox { 
        transform: scale(1);
        opacity: 1;
    }
    .hide-messageBox {
        transform: scale(.95);
        opacity: 0;
    }
    .MessageBox .Icon {
        position: absolute;
        top: -35px;
        left: calc(calc(100% - 70px) / 2);
        width: 70px;
        height: 70px;
        border-radius: 100%;
        display: flex;
        background: white;
    }
        .MessageBox .Icon h1 {
            margin: auto;
            align-self: center;
            font-size: 27pt;
            color: black;
        }
        .MessageBox .Header {
            width: 100%;
            height: 40px;
            display: flex;
            position: relative;
        }
        .MessageBox .Container .Field input {
            font-size: 9pt;
        }
            .Field h5 {
                margin: 0;
                text-align: start;
                margin-bottom: 20px;
            }
            .Field .Input {
                margin-top: 10px;
            }
        .MessageBox .Header .Title {
            height: fit-content;
            align-self: center;
        }
            .MessageBox .Header .Title h5 {
                text-transform: capitalize;
                font-size: 12pt;
            }
            .MessageBox .Header .Control {
                position: absolute;
                top: 0;
                left: calc( 100% - 40px );
            }
            .MessageBox .Header .Control button {
                height: 40px;
                width: 40px;
                background: white;
                color: black;
                font-size: 14pt;
            }
        .MessageBox .Content {
            position: static;
            width: 100%;
            height: fit-content;
        }
            .MessageBox .Content h5 {
                font-weight: normal;
            }
                .MessageBox .Content h5::first-letter {
                    text-transform: capitalize;
                }
            .MessageBox .Content p {
                font-size: 11pt;
                text-align: start;
            }
        .MessageBox .Controls {
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-top: 20px;
        }
            .Controls .Control:first-child {
                margin-right: 20px;
            }
            .Controls button {
                font-size: 10pt;
            }
    @media only screen and (max-width: 600px) {
        .MessageBox {
            left: calc( calc( 100% - 560px ) / 2 );
        }
    }
    @media only screen and (max-width: 600px) {
        .MessageBox {
            width: 400px;
            left: calc( calc( 100% - 460px ) / 2 );
        }
    }
    @media only screen and (max-width: 500px) {
        .MessageBox {
            width: 300px;
            min-height: 200px;
            left: calc( calc( 100% - 360px ) / 2 );
        }
    }
    @media only screen and (max-width: 400px) {
        .MessageBox {
            width: 250px;
            min-height: 100px;
            left: calc( calc( 100% - 310px ) / 2 );
        }
    }