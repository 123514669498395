.Container {
    display: flex;
    position: relative;
    border: 2px solid transparent;
    border-radius: 10px;
    position: relative;
}

.Icon,
.Container button {
    width: 50px;
    height: 50px;
    display: flex;
}

.Icon span,
.Container button span {
    align-self: center;
    margin: auto;
    font-size: 14pt;
}

.Container input {
    border: none;
    width: 100%;
    height: 50px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 15px;
    color: black;
    outline: none;
    font-weight: bold;
    border: 2px solid #dfdfdf;
    background: transparent;
    border-radius: 10px;
    transition: .2s ease;
}

.Container input:active,
.Container input:focus {
    border-color: #D92635;
}

.Container input:disabled {
    opacity: .5;
}
.Container button {
    position: absolute;
    left: calc( 100% - 50px );
}
.ShowAddressList {
    opacity: 1;
}
.HideAddressList {
    opacity: 0;
}
.Container .AddressList {
    position: absolute;
    width: 100%;
    max-height: 300px;
    top: 110%;
    z-index: 10;
    overflow-y: scroll;
    background: rgba(235, 235, 235, 0.486);
    border: 1px solid #e4e4e4;
    backdrop-filter: blur(20px);
    border-radius: 10px;
    transition: .3s ease;
}
    .AddressList p {
        padding: 15px;
        margin: 0;
        transition: .3s;
        text-align: start;
        font-size: 10pt;
    }
        .AddressList p:hover {
            background: #dddddd;
            cursor: pointer;
        }