.Container {
    padding-top: 8vh;
    padding-bottom: 10vh;
    text-align: center;
    overflow: hidden;
}
    .AboutActive .Tab:first-child {
        color: #D92635;
        border-bottom: 2px solid #D92635;
    }
    .TemplatesActive .Tab:nth-child(2) {
        color: #D92635;
        border-bottom: 2px solid #D92635;
    }
    .AdminstrationActive .Tab:nth-child(3) {
        color: #D92635;
        border-bottom-color: #D92635;
    }
    .SettingsActive .Tab:last-child {
        color: #D92635;
        border-bottom-color: #D92635;
    }
    .Header {
        margin: auto;
        width: fit-content;
    }
        .Photo {
            margin: auto;
            width: 200px;
            height: fit-content;
            overflow: hidden;
        }
            .Photo img {
                width: 100%;
                border-radius: 20px;
            }
        .Metadata {
            margin: 10px 0px;
        }
        .Metadata h3 {
            margin: 0;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
        .Metadata h4 {
            margin: 0;
            font-weight: normal;
        }
        .Metadata h4:last-child {
            text-transform: capitalize;
        }
    .Tabs {
        display: flex;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        overflow-y: scroll;
        width: 90%;
        margin: auto;
        margin-top: 20px;
        padding: 0 10px;
        justify-content: center;
    }
        .Tab {
            padding: 10px 20px;
            margin-right: 10px;
            cursor: pointer;
            transition: .3s ease;
            border-bottom: 2px solid transparent;
            min-width: 150px;
        }
            .Tab a {
                color: unset;
                text-decoration: none;
            }
        .Tab p {
            text-transform: capitalize;
            margin: 0;
            color: inherit;
            font-size: 10pt;
        }
    .Content {
        width: 90%;
        margin: auto;
    }
        
@media only screen and (max-width: 450px) {
    .Tabs {
        padding-left: 50px;
        width: 80%;
        justify-content: unset;
    }
}

