.Container {
    width: 310px;
    padding: 10px 20px;
    height: 55px;
    position: fixed;
    top: 150%;
    left: calc( calc( 100% - 350px ) / 2 );
    transition: .3s ease;
    border-radius: 10px;
    box-shadow: 0px 0px 15px #00000027;
    z-index: 15;
    display: flex;
}
    .show { top: calc( 95% - 50px ); }
    .hide { top: 150%; }
    .error { 
        background: #fd3232; 
        color: white; 
    }
    .message { 
        background: #0984e3; 
        color: white; 
    }
    .Container h3 {
        align-self: center;
        padding: 15px;
    }
    .Container h5 {
        margin: auto;
        font-weight: normal;
        font-size: 10pt;
        text-align: start;
    }
        .Container h5::first-letter {
            text-transform: uppercase;
        }
