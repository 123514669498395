.Container {
    width: 100%;
    height: 90%;
    overflow: hidden;
    display: flex;
    text-align: center;
}

    .Menu {
        width: 25%;
        height: 100%;
    }
        .Menu p {
            text-transform: capitalize;
            text-align: start;
            margin: 0;
            padding: 15px;
            padding-left: 52px;
            font-size: 10pt;
            cursor: pointer;
        }
            .Menu p:hover {
                background: #d9263521;
            }
            .Menu .Active {
                color: #D92635;
                background: #d9263521;
            }
    .Content {
        width: 75%;
        height: 100%;
        overflow-y: scroll;
    }