.Button {
    padding: 10px 15px;
    text-transform: capitalize;
    outline: none;
    border-radius: 10px;
    transition: .3s ease;
    font-weight: bolder;
    font-size: 10.2pt;
    border: 2px solid transparent;
    position: relative;
    text-align: center;
    color: black;
    background: #e2e2e2;
    overflow: hidden;
}
    .Button:hover {cursor: pointer;}
    .Button:disabled {
        opacity: .5;
        cursor: not-allowed;
    }
    .Default {
        height: fit-content;
        width: fit-content;
    }
    .Link {
        background: transparent;
        color: #D92635;
        cursor: pointer;
    }
        .Link:hover {
            background: #0985e32f;
        }
    .Primary {
        background:#D92635;
        color: white;
    }
        .Primary:hover {
            background: #a30a17;
        }
    .Secondary {
        background: transparent;
        border-color: #D92635;
        color: #D92635;
    }
        .Secondary:hover {
            background: #d926351f;
        }
    .Warning {
        background: #fed330;
        color: white;
    }
        .Warning:hover {
            background: #d8b229;
        }
    .Error {
        background: transparent;
        border-color: #fd3232;
        color: #fd3232;
    }
        .Error:hover {
            background: #fd323231;
        }
    .Icon {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        transform: scale(.7);
    }
        .Icon span {
            font-size: 14pt;
        }
    .Minimal {
        transform: unset;
        font-size: 15pt;
        background: transparent;
    }
    .Spinner {
        position: absolute;
        width: 102%;
        height: 106%;
        display: flex;
        top: -2%;
        left: -1%;
        margin: 0;
        background: #ffffffee;
        transition: .3s ease;
    }
        .Spinner span {
            align-self: center;
            margin: auto;
            font-size: 15pt;
            animation-name: Spinner;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
    @keyframes Spinner {
        0% {transform: rotate(0deg);}
        50% {transform: rotate(180deg);}
        100% {transform: rotate(360deg);}
    }