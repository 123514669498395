.Container {
    display: flex;
    position: relative;
    width: 100%;
}
    .Icon, .Container button {
        width: 50px;
        height: 50px;
        display: flex;
        position: absolute;
    }
        .Icon span, .Container button span {
            align-self: center;
            margin: auto;
            font-size: 14pt;
        }
    .Container input {
        border: none;
        width: 100%;
        height: 50px;
        padding: 0px;
        padding-left: 10px;
        padding-right: 15px;
        color: black;
        outline: none;
        font-weight: bold;
        border: 2px solid #dfdfdf;
        border-radius: 10px;
        transition: .2s ease;
    }
        .Container input:active,
        .Container input:focus {
            border-color: #D92635;
        }
        .Container input:disabled {
            opacity: .5;
        }