.Container {
    background: url(../../assets/church-icon.png);
    background-repeat: no-repeat;
    background-position: 50%;
    overflow-x: hidden;
    position: relative;
    text-align: center;
    margin-bottom: 10vh;
    padding-top: 5vh;
}
    .Container h2 {
        text-transform: uppercase;
        margin: 0;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 30pt;
        font-family: 'Cormorant', serif;
    }
    .Selector {
        width: fit-content;
        display: flex;
        margin: auto;
    }
        .Services {
            width: 400px;
        }
        .Loading {
            width: 60px;
            height: 60px;
            margin: auto;
            border-radius: 100%;
            overflow: hidden;
            animation-name: Loading;
            animation-duration: 5s;
            animation-fill-mode: both;
            animation-delay: 1s;
            animation-iteration-count: infinite;
            display: flex;
        }
            .Loading img {
                width: 50px;
                height: 50px;
                align-self: center;
                margin: auto;
            }
            @keyframes Loading {
                0% {transform: rotate(0deg);}
                25% {transform: rotate(90deg);}
                50% {transform: rotate(180deg);}
                75% {transform: rotate(270deg);}
                100% {transform: rotate(360deg);}
            }
            .Services p {
                text-transform: capitalize;
                font-weight: normal;
                color: #D92635;
            }
            .NoServices {
                width: 250px;
                margin: auto;
                margin-top: 30%;
            }
                .NoServices h1 {
                    font-size: 30pt;
                    color: #D92635;
                }
                .NoServices p::first-letter {
                    text-transform: uppercase;
                }
                .NoServices P {
                    color: #6e6e6e;
                }
    @media only screen and (max-width: 1200px) {
    
    }
    @media only screen and (max-width: 1000px) {
        
    }
    @media only screen and (max-width: 800px) {
        .Selector {
            display: block;
            height: 130vh;
        }
    }
    @media only screen and (max-width: 650px) {
        .Services {
            margin-left: calc( calc( 100vw - 400px ) / 2 );
        }
    }
    @media only screen and (max-width: 400px) {
        
    }