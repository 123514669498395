.Container {
    height: 100%;
    width: 100%;
}
    .Container > h2 {
        font-weight: normal;
        text-transform: capitalize;
    }
    .Container form {
        width: 90%;
        margin: auto;
    }
    .Container .Field {
        height: fit-content;
    }
        .Field > p {
            margin: 10px 0;
            padding: 0;
            text-align: start;
            font-weight: normal;
            text-transform: capitalize;
        }
        .Field h4 {
            text-align: start;
            margin: 0;
            font-weight: normal;
            font-size: 10pt;
            margin-bottom: 10px;
        }
        .Controls {
            display: flex;
            justify-content: space-evenly;
            margin-top: 20px;
        }
        .Controls button {
           
        }